<template>
  <div>
    <div
      v-if="value.publish_at ?? value.created_at"
      class="lg:mb-3info mb-1 flex items-center text-xxs font-light uppercase lg:text-xl lg:font-extrabold"
    >
      {{ MediaTypeOptions[type ?? value.type] ?? type }}
      <div class="mx-1.5 h-1 w-1 bg-[currentColor] lg:mx-2" />
      <FormatDate
        format="website-medium"
        :value="value.publish_at ?? value.created_at"
      />
    </div>
    <h2 class="as-h3">
      <NuxtLink
        v-if="link && value.slug"
        :to="{name: 'media-slug', params: {slug: value.slug}}"
      >
        {{ value.title }}
      </NuxtLink>
      <span v-else>{{ value.title }}</span>
    </h2>

    <MediaPlayer
      class="mt-8"
      :poster-url="value.thumbnail_url ?? ''"
      :youtube-id="youtubeId ?? ''"
    />
  </div>
</template>

<script setup lang="ts">
import type {MediaQuery} from '@graphql/graphql';
import {MediaTypeOptions} from '@constants/labels/media';

const props = defineProps<{
  link?: boolean
  type?: string
  value: NonNullable<MediaQuery['media']>['data'][number]
}>();

const preferInterpreter = usePrefersInterpreter();

const youtubeId = computed(() => (preferInterpreter.value ? props.value.youtube_with_interpreter_id : null) ?? props.value.youtube_id);
</script>
